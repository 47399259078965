
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter, useRoute } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import { Form, Field } from "vee-validate";
import { object, string } from "yup";
import { hideModal } from "@/core/helpers/dom";

export default defineComponent({
    name: "builder",
    components: {},
    data() {
        return {
            loading: true,
            reservations: [],
            event: {} as any,
            newEvent: {} as any,
            perm_pos: "",
        };
    },
    setup() {
        const router = useRouter();

        onMounted(() => {
            setCurrentPageBreadcrumbs("Events", []);
        });

        return { router };
    },
    //mounted() {},
    methods: {},
});
